<template>
  <v-card color="transparent" tile elevation="0">
    <v-card class="py-sm-8 py-4" color="light_gray" tile elevation="0">
      <v-row justify="center">
        <v-col cols="11" lg="9">
          <span class="text-sm-h5 text-body-1 font-weight-bold">{{ $t(`label.myCart`) }}</span>
        </v-col>
      </v-row>
    </v-card>
    <v-row justify="center" class="my-8" no-gutters>
      <v-col cols="11" lg="9">
        <v-row>
          <v-col md="7" cols="12">
            <v-card class="pa-sm-6 pa-2 py-6 rounded-lg" color="transparent" tile elevation="5">
              <v-data-table
                :items-per-page="cart.length"
                class="cart-table unbox_scrollbar"
                item-key="uuid"
                v-model="selectedProducts"
                :items="cart.items"
                hide-default-footer
                ref="cartTable"
                :no-data-text="$t(`label.cartEmpty`)"
              >
                <template v-slot:top>
                  <div class="">
                    <v-checkbox
                      class="d-inline-block unbox_black--text"
                      :ripple="false"
                      :input-value="isSelectedAll"
                      hide-details
                      color="#6EE7B7"
                      @change="selectAllItems"
                      :label="$t(`label.selectAll`)"
                    ></v-checkbox>
                  </div>
                </template>

                <template v-slot:item="{ item, isSelected, select }">
                  <template>
                    <tr>
                      <td class="checkbox-ticker px-0">
                        <v-simple-checkbox
                          :class="cartStock.find((x) => x.display_sku == item.productDisplaySku) != null && cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity > 0 ? '' : 'd-none'"
                          :value="isSelected"
                          :ripple="false"
                          color="#6EE7B7"
                          @input="select"
                          :disabled="cartStock.find((x) => x.display_sku == item.productDisplaySku) != null ? cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity <= 0 : false"
                        />
                      </td>
                      <td class="full-width">
                        <v-card class="ma-2 rounded-lg" color="transparent" tile elevation="2">
                          <table class="full-width d-table">
                            <tr>
                              <td class="cart-productColumn image cursor-pointer v-align-top" @click="goToProductDetailPage(item)">
                                <img class="cart-productImage ma-2" :src="`${shared.MEDIA_SERVER_URL}/products/variants/${item.productDisplaySku}.png`" alt="" />
                              </td>
                              <td class="cart-productColumn">
                                <div class="py-4 px-2">
                                  <div class="cursor-pointer" @click="goToProductDetailPage(item)">
                                    <div
                                      class="font-weight-black text-body-1 mb-2 red--text"
                                      :class="
                                        cartStock.find((x) => x.display_sku == item.productDisplaySku) != null && cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity > 0
                                          ? 'd-none'
                                          : ''
                                      "
                                    >
                                      {{ $t(`label.soldOut`) }}
                                    </div>
                                    <div class="font-weight-bold text-sm-body-1 text-body-2 mb-2">{{ item.productName }}</div>
                                    <div class="text-sm-body-1 text-body-2 mb-2">{{ item.variation }}</div>

                                    <div :class="item.discountedRate != 0 ? 'd-visible' : 'd-invisible'">
                                      <v-row align="center" justify="space-between" no-gutters>
                                        <v-col cols="5">
                                          <div class="text-caption text-decoration-line-through text_light_gray--text">
                                            {{ $shared.formatCurrency(item.unitPrice) }}
                                          </div>
                                        </v-col>
                                        <v-col cols="4" md="5" xl="3" :class="item.discountedRate >= 3 ? 'd-visible' : 'd-invisible'">
                                          <div class="productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text">
                                            <span class="white--text text-caption text-uppercase font-weight-bold">- {{ item.discountedRate }}%</span>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </div>

                                    <div class="font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2">{{ $shared.formatCurrency(item.discountedPrice) }}</div>
                                  </div>
                                  <v-row align="center" class="mt-2">
                                    <v-col sm="auto">
                                      <span class="text-sm-body-1 text-body-2">{{ $t(`label.quantity`) }}</span>
                                    </v-col>
                                    <v-col sm="auto">
                                      <div style="width: 120px">
                                        <v-text-field class="cart-quantity" hide-details outlined v-model.number="item.quantity" @input="checkAvailableQuantity(item)" dense type="number">
                                          <template v-slot:prepend>
                                            <v-btn class="font-weight-bold my-n2 text-h6 px-0" max-width="15px" min-width="15px" height="40px" text @click.stop="decreaseCartItemQuantity(item)">
                                              -
                                            </v-btn>
                                          </template>
                                          <template v-slot:append-outer>
                                            <v-btn
                                              :disabled="
                                                cartStock.find((x) => x.display_sku == item.productDisplaySku) != null &&
                                                cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity <= item.quantity
                                              "
                                              class="font-weight-bold my-n2 text-h6 px-0"
                                              max-width="15px"
                                              min-width="15px"
                                              height="40px"
                                              text
                                              @click.stop="increaseCartItemQuantity(item)"
                                            >
                                              +
                                            </v-btn>
                                          </template>
                                        </v-text-field>
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="item.insurance && item.discountedPrice < 8000.01 && item.discountedPrice > 0">
                                    <v-col cols="12">
                                      <div>
                                        <v-row no-gutters align="top" class="mb-2">
                                          <v-col cols="auto"><v-checkbox class="mt-0" hide-details v-model="item.requiredInsurance" @change="(e) => removeItemInsurance(e, item)"></v-checkbox></v-col>
                                          <v-col>
                                            <p class="mb-0">
                                              {{ $t(`label.insurancePlan`, [$shared.formatCurrency(item.insurance.unit_price)]) }}
                                              <span class="link-text cursor-pointer" @click="openProtectionPlanPage">
                                                {{ $t(`label.tncsApply`) }}
                                                <v-icon small v-text="'$exclamationMarkIcon'" class="stroke--link_text"></v-icon>
                                              </span>
                                            </p>
                                          </v-col>
                                        </v-row>
                                        <div v-if="item.requiredInsurance">
                                          <div class="font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2">{{ $shared.formatCurrency(item.insurance.unit_price) }}</div>
                                          <div>
                                            <v-row align="center" class="mt-2">
                                              <v-col sm="auto">
                                                <span class="text-sm-body-1 text-body-2">{{ $t(`label.quantity`) }}</span>
                                              </v-col>
                                              <v-col sm="auto">
                                                <div style="width: 120px">
                                                  <v-text-field
                                                    class="cart-quantity"
                                                    hide-details
                                                    outlined
                                                    v-model.number="item.insurance.quantity"
                                                    @keyup="checkInsuranceQuantity(item)"
                                                    dense
                                                    type="number"
                                                  >
                                                    <template v-slot:prepend>
                                                      <v-btn
                                                        class="font-weight-bold my-n2 text-h6 px-0"
                                                        max-width="15px"
                                                        min-width="15px"
                                                        height="40px"
                                                        text
                                                        @click.stop="decreaseInsuranceQuantity(item)"
                                                      >
                                                        -
                                                      </v-btn>
                                                    </template>
                                                    <template v-slot:append-outer>
                                                      <v-btn
                                                        :disabled="
                                                          cartStock.find((x) => x.display_sku == item.productDisplaySku) != null &&
                                                          cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity <= item.quantity
                                                        "
                                                        class="font-weight-bold my-n2 text-h6 px-0"
                                                        max-width="15px"
                                                        min-width="15px"
                                                        height="40px"
                                                        text
                                                        @click.stop="increaseInsuranceQuantity(item)"
                                                      >
                                                        +
                                                      </v-btn>
                                                    </template>
                                                  </v-text-field>
                                                </div>
                                              </v-col>
                                            </v-row>
                                          </div>
                                        </div>
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <div class="text-right red--text text-body-2 text-capitalize cursor-pointer" @click="removeCartItem(item)">{{ $t(`label.remove`) }}</div>
                                    </v-col>
                                  </v-row>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </v-card>
                      </td>
                    </tr>
                  </template>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col md="5" cols="12">
            <v-card class="pa-6 rounded-lg" color="transparent" tile elevation="5">
              <v-row justify="space-between" class="mb-3">
                <v-col cols="auto">
                  <div class="font-weight-bold unbox_black--text text-h6">{{ $t(`label.inTotal`) }}</div>
                </v-col>
                <v-col cols="auto">
                  <div class="font-weight-bold unbox_red--text text-h6">
                    {{ cartTotalAmount }}
                  </div>
                </v-col>
              </v-row>

              <div class="red--text text-body-2 my-4" v-if="noSelectCheckOutProductError">{{ $t(`label.pleaseSelectCheckout`) }}</div>
              <v-btn block color="unbox_red" class="rounded-lg font-weight-bold text-body-1 py-6 white--text" @click="checkout">
                {{ $t(`label.checkOut`) }}
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { localeHelper } from '@/utils'
import { debounce } from 'lodash'
import { SESSION, SHARED } from '@/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import { ORDER_REMOVE_CART_ITEM, ORDER_UPDATE_CART_ITEM } from '@/store/order.module'
export default {
  name: 'cart',
  data: () => ({
    shared: SHARED,
    selectedProducts: [],
    isSelectedAll: false,
    noSelectCheckOutProductError: false
  }),
  computed: {
    cartStock() {
      return this.$store.state.stock.cartStock
    },
    cart() {
      let t = this.$store.state.order.cartWithPrices
      for (let i = 0; i < t.items.length; i++) {
        if (t.items[i] === undefined) {
          t.items.splice(i, 1)
        }
      }
      return t
    },
    cartTotalAmount() {
      let totalCartAmount = 0
      this.selectedProducts.forEach((product) => {
        totalCartAmount += product.discountedPrice * product.quantity
        if (product.requiredInsurance) {
          totalCartAmount += product.insurance.unit_price * product.insurance.quantity
        }
      })

      return this.$shared.formatCurrency(totalCartAmount)
    }
  },
  watch: {
    selectedProducts() {
      this.noSelectCheckOutProductError = false
      this.isSelectedAll = this.selectedProducts.length == this.cart.length && this.selectedProducts.length > 0
    },
    cart(fullCart) {
      // Update selectedProducts when cart updates, because the selected items in cart no longer have same reference as selectedProducts after update
      const selectedProductsUuid = this.selectedProducts.map((p) => p.uuid)
      this.selectedProducts = fullCart.items.filter((i) => selectedProductsUuid.includes(i.uuid))
    }
  },
  methods: {
    openProtectionPlanPage() {
      let route = this.$router.resolve({
        name: ROUTE_NAME.PROTECTION_PLAN
      })
      window.open(route.href, '_blank')
    },
    checkAvailableQuantity(item) {
      let itemStock = this.cartStock.find((x) => x.display_sku == item.productDisplaySku)
      if (item.quantity > itemStock.quantity) {
        item.quantity = itemStock.quantity
        this.$root.$master.showSnackbarMessage(localeHelper.getMessage(`label.remainingItemQuantityMessage`, [itemStock.quantity]))
      } else if (item.quantity <= 0) {
        item.quantity = 1
      }
      this.updateBackendCartItemQuantity(item)
    },
    increaseCartItemQuantity(item) {
      let itemStock = this.cartStock.find((x) => x.display_sku == item.productDisplaySku)
      if (itemStock != undefined && item.quantity < itemStock.quantity) {
        item.quantity = item.quantity + 1
        this.updateBackendCartItemQuantity(item)
      }
    },
    decreaseCartItemQuantity(item) {
      if (item.quantity > 1) {
        item.quantity = --item.quantity
      }

      if (item.insurance.quantity > item.quantity) {
        item.insurance.quantity = item.quantity
      }
      this.updateBackendCartItemQuantity(item)
    },
    updateBackendCartItemQuantity: debounce(async function (item) {
      const addOnServices = []
      if (item.requiredInsurance) {
        addOnServices.push({
          name: item.insurance.name,
          type: item.insurance.type,
          display_sku: item.insurance.display_sku,
          quantity: Math.min(item.insurance.quantity, item.quantity)
        })
      }

      const requestBody = {
        itemUuid: item.uuid,
        cartUuid: item.cartUuid,
        quantity: item.quantity,
        discountCode: '',
        discountPercentage: 0,
        discountAmount: 0,
        markUpPrice: 0,
        markUpPercentage: 0,
        services: addOnServices,
        remark: ''
      }
      await this.$store.dispatch(ORDER_UPDATE_CART_ITEM, { data: requestBody })
      await this.$root.$master.updateCartInfo()
    }, 500),
    increaseInsuranceQuantity(item) {
      if (item.insurance.quantity < item.quantity) {
        item.insurance.quantity = ++item.insurance.quantity
        this.updateBackendCartItemQuantity(item)
      }
    },
    decreaseInsuranceQuantity(item) {
      if (item.insurance.quantity > 1) {
        item.insurance.quantity = --item.insurance.quantity
        this.updateBackendCartItemQuantity(item)
      }
    },
    checkInsuranceQuantity(item) {
      if (item.insurance.quantity > item.quantity) {
        item.insurance.quantity = item.quantity
        this.$root.$master.showSnackbarMessage(localeHelper.getMessage(`label.insuranceOverProductLimit`, [item.quantity]))
      } else if (item.insurance.quantity < 0) {
        item.insurance.quantity = item.quantity
      } else if (item.quantity <= 0) {
        item.insurance.quantity = 1
      }
      this.updateBackendCartItemQuantity(item)
    },
    async removeCartItem(item) {
      const data = {
        uuid: item.cartUuid,
        itemUuid: item.uuid
      }
      await this.$store.dispatch(ORDER_REMOVE_CART_ITEM, { data })
      await this.$root.$master.updateCartInfo()
    },
    selectAllItems(val) {
      this.$refs.cartTable.toggleSelectAll(val)
    },
    checkout() {
      if (this.selectedProducts.length <= 0) {
        this.noSelectCheckOutProductError = true
      } else {
        localStorage.setItem(SESSION.CHECKOUT_CART, JSON.stringify(this.selectedProducts))
        this.$router.push({
          name: ROUTE_NAME.SHOP_CHECKOUT
        })
      }
    },
    goToProductDetailPage(item) {
      let productName = item.productName.replace(/\s+/g, '-').toLowerCase()
      this.$router.push({
        name: ROUTE_NAME.SHOP_PRODUCT,
        params: {
          productName: `${productName}-${SHARED.PRODUCT_GROUP_PREFIX}${item.group}`
        }
      })
    },
    removeItemInsurance(requiredInsurance, item) {
      item.insurance.quantity = requiredInsurance ? 1 : 0
      this.updateBackendCartItemQuantity(item)
    }
  }
}
</script>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.checkbox-ticker {
  width: 15px;
}

.cartDisable {
  opacity: 0.4;
}

.productContent-discountRate {
  background-color: var(--v-sharp_red-base);
}
</style>
